import React from 'react'
import availableLangs from '@localization/availableLangs'
import Report0820PageContainer from '@containers/ReportPagesContainers/Report0820PageContainer'
import SeoContainer from '@containers/SeoContainer'
import report0820FromScript from '../../externalScriptsCode/report0820FromScript'

const IndexPage = () => {
  return (
    <>
      <SeoContainer pageLang={availableLangs.de} pagePath='report0820'>
        <script async='true' dangerouslySetInnerHTML={{ __html: report0820FromScript }} />
      </SeoContainer>

      <Report0820PageContainer />
    </>
  )
}

export default IndexPage
