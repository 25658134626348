import React from 'react'
import Report0820Form from '@common/forms/Report0820Form'
import CommonReportContainer from './CommonReportContainer'

const Report0820PageContainer = () => {
  return (
    <CommonReportContainer
      FormComponent={Report0820Form}
      reportImageName='report_august.png'
    />
  )
}

export default Report0820PageContainer
